/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { Map } from "immutable";
import { Action } from "redux";
import { ActionTypeKeys, Config, ReducerFunc } from "./ActionTypes";
import { PageRef } from "./AppDisplays";
import { FileDownload } from "./FineSearcher/Types";
import { RemoteErrorType, RemoteScope } from "./RemoteTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

export const isRemoteLoading =
    (state: ReduxState, scope: RemoteScope): boolean =>
        state.mapProp("remotes", r => r.has(scope));

export const isRemoteLoadingAny =
    (state: ReduxState): boolean =>
        state.mapProp("remotes", r => !r.isEmpty());

export interface RemoteErrors {
    message: string;
    type: RemoteErrorType;
}

interface TState {
    conf: Config;
    client: AxiosInstance;
    remotes: Map<RemoteScope, Mary.utils.Opt<CancelTokenSource>>;
    remote:  Mary.utils.State<RemoteState>;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    mary?: Mary.utils.MaryState;
    currentTheme:  Mary.theme.Themes;
}

/**
 *
 */
export interface RemoteState {
    [RemoteScope.FINE_SEARCHER]?: FileDownload;
    // [RemoteScope.FINE_SEARCHER]?: {};
}

export type ReduxState = Mary.utils.State<TState>;

export type ReducerFn<A extends Action<ActionTypeKeys>> =
    ReducerFunc<ReduxState, A>;

export interface CurrentInit {
    pages: PageRef;
}

export type Filters = Readonly<{
    // [FilterScope.CLAIMS]: ClaimFilters;
}>;

export type FilterStates = {
    [K in keyof Filters]: Mary.utils.State<Filters[K]>;
};

export const clearState = (s: ReduxState) => s.clear({ mary: true, conf: true });

export const ReduxStateInit: (config: Config) => Mary.utils.StateInit<TState> =
    config => (): TState => ({
        conf: config,
        client: Axios.create({
            baseURL: config.apiEndpoint,
        }),
        remotes: Map(),
        remote:  Mary.utils.State.create<RemoteState>(() => ({})),
        remoteErrors: Map(),
        mary: undefined,
        currentTheme: localStorage.getItem("currentTheme") as  Mary.theme.Themes ??  Mary.theme.Themes.VWPFS,
    });

export const initTestState: ReduxState =  Mary.utils.State.create(ReduxStateInit({apiEndpoint: ""}));
