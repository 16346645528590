import { connect } from "react-redux";

import { DispatchFunc } from "../../store/ActionTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { DispatchProps, VerifyContext } from "./VerifyContext";

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    handleBrowserNotification: (message: string) => dispatch(
        Mary.utils.modalShow("genericwarningmodal", () => Mary.utils.verifycontext.ModalGenericWarning({
            id: "genericwarningmodal",
            onClose: () =>  Mary.utils.modalClose("genericwarningmodal"), message: message}))),
    handleNewAppVersion: (message: string) => dispatch(
        Mary.utils.modalShow("newversionmodal", () => Mary.utils.verifycontext.ModalNewVersionAvailable({
            id: "newversionmodal",
            onClose: () => dispatch(Mary.utils.modalClose("newversionmodal")),
            message: message,
        }))),
    closeModal: (id: string) => dispatch(Mary.utils.modalClose(id)),
});

/**
 *
 */
export const VerifyContextWrapper = connect(
    undefined,
    mapDispatchToProps,
)(VerifyContext);

export const testUtil = {
    mapDispatchToProps: mapDispatchToProps,
};
