import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { ReduxState } from "./ReduxState";

/**
 * Thunk is the ThunkAction type used by Hermann for declaring thunks / action
 * creators that return thunks.
 *
 * In ThunkAction<R, S, E, A>:
 * R => Return value, a plain redux Action type returned by the thunk (if
 * applicable). May be void.
 * S => State type, return type of Dispatch<S> and getState().
 * E => ExtraArgument, unused.
 * A => ?
 */
export type Thunk<R extends Action<ActionTypeKeys> | void> =
    ThunkAction<R, ReduxState, undefined, Action<ActionTypeKeys>>;

/**
 * DispatchFunc gives an alias for the Dispatch function provided by Redux for
 * easier type handling.
 */
// export type DispatchFunc = Dispatch<Action<ActionTypeKeys>>;
export type DispatchFunc = ThunkDispatch<
ReduxState,
undefined,
Action<ActionTypeKeys>
>;

/**
 * StateFunc gives an aliad for the getState function provided by redux-thunk
 * for easier type handling.
 */
export type StateFunc = () => ReduxState;

/**
 * ReduxStateReducer
 */
export type ReducerFunc<S, A extends Action<ActionTypeKeys>> = (state: S, action: A) => S;

/*
 * Config specifies environment-related configuration parameters for the client.
 */
export type Config = Readonly<{
    apiEndpoint: string;
}>;

/**
 *
 */
export enum ActionTypeKeys {
    /**
     * APP_INIT is triggered when the App component is mounted, meaning the user
     * is successfully authenticated and we can start loading the first dataset.
     */
    REMOTE_TRIGGER = "REMOTE_TRIGGER",
    REMOTE_CANCEL = "REMOTE_CANCEL",
    REMOTE_RESPONSE = "REMOTE_SUCCESS",
    REMOTE_ERROR = "REMOTE_ERROR",
    REMOTE_CLEAR_ERROR = "REMOTE_CLEAR_ERROR",
    REMOTE_CLEAR_RESPONSE = "REMOTE_CLEAR_RESPONSE",

    UPDATE_THEME = "UPDATE_THEME",
}
