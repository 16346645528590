/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReleaseVersion as MaryReleaseVersion } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms";
import React from "react";

export const ReleaseVersion: React.FC<{}> = () => {

    const packageForReleaseVersion: {version?: string} | undefined = require("../../../../package.json");
    const appVersion = packageForReleaseVersion?.version as unknown as string ?? undefined;
    return(
        <MaryReleaseVersion appVersion={appVersion} withMaryVersion={!!appVersion} />
    );
};
