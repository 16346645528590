/* eslint-disable */
import {
    Action,
    Store,
    applyMiddleware,
    compose,
    createStore,
} from "redux";
import thunk from "redux-thunk";
import { ActionTypeKeys } from "./ActionTypes";
import { ReduxState, ReduxStateInit } from "./ReduxState";
import {
    remoteCancelReducer,
    remoteClearErrorReducer,
    remoteClearResponseReducer,
    remoteErrorReducer,
    remoteResponseReducer,
    remoteTriggerReducer,
} from "./RemoteActions";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

// DO NOT TOUCH - WE KNOW WHAT WE ARE DOING, I SWEAR
// https://github.com/palantir/tslint/issues/144#issuecomment-195453810
//
// TODO: the disabled lint may or may not be needed here.

/* tslint:disable */
declare let window: any;
const composeEnhancers: any = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* tslint:enable */

/**
 * Reducer defines the type signature of this reducer.
 */
type StoreConfig = Readonly<{
    apiEndpoint: string;
}>;

const rootReducer =
    Mary.utils.withMaryReducers(
        Mary.utils.Reducers
            .create<ActionTypeKeys | Mary.utils.MaryActionTypeKeys, ReduxState>()
            .register(
                ActionTypeKeys.REMOTE_TRIGGER,
                remoteTriggerReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CANCEL,
                remoteCancelReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_RESPONSE,
                remoteResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_RESPONSE,
                remoteClearResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_ERROR,
                remoteErrorReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_ERROR,
                remoteClearErrorReducer,
            )
    );

export const createReduxStore: (conf: StoreConfig) => Store<ReduxState> =
    conf => createStore<ReduxState, Action<ActionTypeKeys | Mary.utils.MaryActionTypeKeys>, {}, {}>(
        (state, action) => rootReducer.reduce(state || Mary.utils.State.create(ReduxStateInit(conf)), action),
        composeEnhancers(applyMiddleware(thunk)),
    );
