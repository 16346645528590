/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from "axios";
import { FileDownload } from "./Types";

export const mapFileSeacherResponse =
    (resp: AxiosResponse): FileDownload => ({
        statusCode: resp?.status,
        downloadUrl: resp?.request?.responseURL,
    });

