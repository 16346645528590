/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { FileDownload } from "../../../../store/FineSearcher/Types";
import { ReduxState, isRemoteLoading } from "../../../../store/ReduxState";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "../../../../store/RemoteActions";
import { RemoteScope } from "../../../../store/RemoteTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { FormField } from "../../07-organisms/Form/Field";

interface State {
    licensePlate: string;
    dispositionNumber: string;
}

interface OwnProps {
    asPage: boolean;
}

interface StateProps {
    results?: FileDownload;
    loadingDamagePDF: boolean;
}

/**
 *
 */
interface DispatchProps {
    fileDownload: (licensePlate: string, dispositionNumber: string) => void;
    clearResults: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    results: s.prop("remote").prop(RemoteScope.FINE_SEARCHER),
    loadingDamagePDF: isRemoteLoading(s, RemoteScope.FINE_SEARCHER),
});


/**
 *
 */
const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    fileDownload: (licensePlate: string, dispositionNumber: string) =>
        dispatch(remoteTrigger(RemoteScope.FINE_SEARCHER, {
            licensePlate: licensePlate,
            dispositionNumber: dispositionNumber,
        })),
    clearResults: () => (
        (
            dispatch(remoteClearResponse(RemoteScope.FINE_SEARCHER)),
            dispatch(remoteClearError(RemoteScope.FINE_SEARCHER))
        )),
});

class FineSearcherComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            licensePlate: "",
            dispositionNumber: "",
        };
        this.clear = this.clear.bind(this);
    }

    public render() {
        return (
            <>
                <Mary.base.Container
                    theme={{
                        shadow: this.props.asPage ? Mary.theme.ThemeShadowSizes.SMALL : undefined,
                        palette: this.props.asPage ? Mary.theme.ThemePalette.CONTRAST_PRIMARY : undefined,
                        padding: {
                            "": { y: 3 },
                            "sm": { y: 4 },
                        },
                    }}
                >
                    <Mary.views.sections.Header
                        title="Bekeuringzoeker"
                        description={
                            `U kunt hier uw bekeuring opzoeken, vul hieronder uw kenteken en het beschikkingsnummer in.
                            Hierna zullen wij u voorzien van de bekeuring die u kunt downloaden als PDF.`
                        }
                    />
                    {this.props.results?.statusCode === 200 && (
                        <>
                            <Mary.base.Div
                                theme={{
                                    padding: {
                                        "": { b: 2 },
                                        "sm": { b: 3 },
                                    },
                                }}
                            >
                                <b>
                                    <Mary.atoms.Blockquote
                                        style={{ fontWeight: 700 }}
                                    >
                                        {`Wij hebben uw bekeuring voor
                                        ${Mary.utils.formatLicensePlate(this.state.licensePlate) ?? "-"}
                                        met beschikkingsnummer ${this.state.dispositionNumber} gevonden,
                                        u kunt de bekeuring nu downloaden.`}
                                    </Mary.atoms.Blockquote>
                                </b>
                            </Mary.base.Div>
                            <Mary.molecules.ButtonsWrapper
                                orientations={{ [Mary.theme.ThemeBreakpoints.XS]: Mary.molecules.Orientation.VERTICAL}}
                                alignment={Mary.molecules.Alignment.LEFT}
                            >
                                <Mary.atoms.button.Button
                                    className="scl-a-btn--big"
                                    theme={{
                                        palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                    }}
                                    link={{ href: this.props.results?.downloadUrl ?? "", target: Mary.atoms.link.LinkTarget.BLANK }}
                                >
                                    Download bekeuring
                                </Mary.atoms.button.Button>
                                <Mary.atoms.button.Button
                                    theme={{
                                        palette: Mary.theme.ThemePalette.CONTRAST_PRIMARY,
                                    }}
                                    link={{ onClick: this.clear }}
                                    className="scl-a-btn-no-after"
                                >
                                    Ga terug
                                </Mary.atoms.button.Button>
                            </Mary.molecules.ButtonsWrapper>
                        </>
                    )}
                    {(this.props.results?.statusCode === 400 ||  this.props.results?.statusCode === 404 || this.props.results?.statusCode === 403) && (
                        <Mary.base.Div
                            theme={{
                                padding: {
                                    "": { b: 2 },
                                    "sm": { b: 3 },
                                },
                            }}
                        >
                            <Mary.atoms.Blockquote
                                theme={{
                                    paletteState: Mary.theme.ThemePaletteState.DANGER,
                                }}
                                style={{ fontWeight: 700 }}
                            >
                                {this.props.results?.statusCode === 403 ? (
                                    <>
                                    Oeps er is iets misgegaan.
                                    Wij kunnen geen verbinding maken met de API.
                                    </>
                                ) : (
                                    <>
                                    Wij kunnen uw bekeuring niet vinden,
                                    probeer het nog eens of neem contact met ons op <Mary.atoms.link.Link link={{href: "mailto:bekeuringen@vwpfs.nl"}}>bekeuringen@vwpfs.nl</Mary.atoms.link.Link>
                                    </>
                                )}
                            </Mary.atoms.Blockquote>
                        </Mary.base.Div>
                    )}
                    {this.props.loadingDamagePDF ?
                        (
                            <Mary.atoms.loadingindicator.LoadingIndicator
                                type={Mary.atoms.loadingindicator.LoadingIndications.DEFAULT}
                                theme={{ palette: Mary.theme.ThemePalette.BRAND_PRIMARY }}
                            />
                        ) :
                        (!this.props.results || this.props.results.statusCode !== 200) && (
                            <>
                                <FormField
                                    type="licensePlate"
                                    label="Kenteken"
                                    placeholder="CLD-000-X"
                                    onChange={(value?: string | number) =>
                                        this.setState({ licensePlate: value?.toString() ?? "" },
                                        )}
                                    value={Mary.utils.isValidLicensePlate(this.state.licensePlate)
                                        ? Mary.utils.formatLicensePlate(this.state.licensePlate)
                                        : this.state.licensePlate.toUpperCase()}
                                    validationFunction={
                                        (value: string | number) =>
                                            Mary.organisms.hasLicensePlateValidation(value,
                                                "Kenteken ingevuld")}
                                />
                                <FormField
                                    label="Beschikkingsnummer"
                                    onChange={(value?: string | number) =>
                                        this.setState({ dispositionNumber: value?.toString() ?? "" })}
                                    value={this.state.dispositionNumber}
                                    validationFunction={
                                        (value: string | number) =>
                                            Mary.organisms.hasValueValidation(value,
                                                "Beschikkingsnummer ingevuld")}
                                />
                                <Mary.molecules.ButtonsWrapper
                                    orientations={{ [Mary.theme.ThemeBreakpoints.XS]: Mary.molecules.Orientation.VERTICAL}}
                                    alignment={Mary.molecules.Alignment.LEFT}
                                >
                                    <Mary.atoms.button.Button
                                        className="scl-a-btn--big"
                                        theme={{
                                            palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                        }}
                                        buttonType="submit"
                                        link={{
                                            onClick: () =>
                                                this.props.fileDownload(
                                                    Mary.utils.parseLicenseplate(this.state.licensePlate) ?? this.state.licensePlate.toUpperCase(),
                                                    this.state.dispositionNumber),
                                        }}
                                        disabled={!this.state.licensePlate || !this.state.dispositionNumber}
                                    >
                                        { Mary.utils.getText("app.search", "Zoeken")}
                                    </Mary.atoms.button.Button>
                                </Mary.molecules.ButtonsWrapper>
                            </>
                        )}
                </Mary.base.Container>
            </>
        );
    }

    private clear() {
        this.props.clearResults();
        this.setState({ licensePlate: "", dispositionNumber: "" });
    }
}

export const FineSearcher = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FineSearcherComp);
