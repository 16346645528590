import { mapFileSeacherResponse } from "./FineSearcher/RemoteConfig";
import { remoteClearError, remoteClearResponse } from "./RemoteActions";
import {
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.FINE_SEARCHER]: {
        scope: RemoteScope.FINE_SEARCHER,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.FINE_SEARCHER));
            d(remoteClearError(RemoteScope.FINE_SEARCHER));
        },
        pathMapper: (_s, _ctx) => "/fine",
        paramsMapper: (_s, ctx) => ({
            "license_plate": ctx.licensePlate,
            "payment_reference": ctx.dispositionNumber,
            "x-api-key": process.env.REACT_APP_API_KEY,
        }),
        resMapper: mapFileSeacherResponse,
    },
};
